import React, { useEffect, useState } from "react";
import { IconButton, Stack } from "@mui/material";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

const SearcherComponent = (props) => {

    const { setBarCode } = props;
    const [valueInput, setValueInput] = useState('');

    // Control the changes in the input field and submit
    const onSearchValueChange = (event) => {
        const inputValue = event.target.value;
        setValueInput(inputValue);
    }

    // Control the submit action
    const handleSubmit = () => {
        setBarCode(valueInput);
    }

    // Request the submit by press the Enter key
    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                setBarCode(valueInput);
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [valueInput]);

    return (
        <Stack
            direction="row"
            sx={{
                marginTop: '30px',
                height: '10px',
            }}
        >
            <input
                autoFocus 
                type="number"
                pattern="[0-9]*"
                size="small"
                value={valueInput}
                onChange={onSearchValueChange}
                style={{ border: 'none',  color: 'white', outline: 'none' }}
            />

            <IconButton
                size="small"
                sx={{
                    top: '-8px',
                    left: '-40px',
                    marginY: '10px',
                    height: '5px',
                    color: 'white'
                }}
                onClick={handleSubmit}
            >
                <TravelExploreIcon />
            </IconButton>

        </Stack>
    )
}

export default SearcherComponent;