import React, { useEffect, useState } from "react";
import { Container, Box, Grid, Button } from "@mui/material";
import Carousel from 'react-bootstrap/Carousel';
import CircularProgress from '@mui/material/CircularProgress';

import SearcherComponent from "./components/SearcherComponent";

import logo from "./assets/logo_dermatodo.png";
import barcode from "./assets/barcode.png";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

//const urlFetch = process.env.END_POINT;
const urlFetch = 'https://kiosk.dermatodo.com/api/product/';

const App = () => {

    const tiempoRecarga = 3600000;
    const tiempoRegreso = 45000;

    // Input values from the search form
    const [barCode, setBarCode] = useState('');

    // Loading state
    const [spinner, setSpinner] = useState(false);

    // Response of the API with the response of the ips
    const [barCodeList, setBarCodeList] = useState([]);

    // If exist image for the lab
    const [altLab, setAltLab] = useState(false);

    function reset() {
        window.location.reload(false);
    }

    // Recargar pagina cada hora
    useEffect(() => {
        const resetPagina = setInterval(() => {
            console.log('reset')
            reset();
        }, tiempoRecarga);
        return () => clearInterval(resetPagina);
    }, [])

    function regresar() {
        setBarCode('');
        setBarCodeList('');
    }

    useEffect(() => {
        if (barCode !== '') {
            setSpinner(true);
            fetch(urlFetch + barCode)
                .then(response => response.json())
                .then(data => {
                    if (data.precio_final > 0) {
                        setBarCodeList(data);
                    } else {
                        setBarCode('');
                    }
                    setSpinner(false);
                });

            const timer = setTimeout(() => {
                regresar();
            }, tiempoRegreso);
            return () => clearTimeout(timer);

        }
    }, [barCode]);

    

    function monedaFormato(valor) {
        return (Math.round(valor * 100) / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    function detalles(detalles) {
        let lista = '';
        if (detalles) {
            const entrada = JSON.parse(detalles);
            lista = entrada.map((dato) => 
                <Grid item xs={6} key={Object.keys(dato)}>
                    <div className="titulo">{Object.keys(dato)}: <span className="texto">{dato[Object.keys(dato)]}</span></div>
                </Grid>
            )
        }
        return (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                { lista }
            </Grid>
        )
    }


    function carrusel(imagenes) {
        if (imagenes) {
            const images = imagenes.split("|*|");
            const numImages = images.length > 1;
            const imageList = images.map((imagen) =>
                <Carousel.Item key={imagen}>
                    <img
                        className="d-block w-100"
                        src={imagen}
                        alt=""
                    />
                </Carousel.Item>
            );
            return (
                <Carousel controls={numImages} indicators={numImages}>
                    {imageList}
                </Carousel>
            )
        }
    }

    function handleImagen(event) {
        event.target.style.display = 'none';
        setAltLab(true);
    }

    return (
        <Container maxWidth={false} disableGutters
            sx={{
                width: '100vw',
            }}
        >


            {spinner &&
                <Box sx={{ display: 'flex', width: '100vw', height: '100vh', background: '#fff0f0', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress size="6rem" style={{'color': '#1894d2'}} />
                </Box>
            }





            {(barCode === '' && !spinner) &&

                <Container maxWidth={false} disableGutters>

                    <Container maxWidth={false}
                        sx={{
                            backgroundColor: 'white',
                            height: '50vh',
                            textAlign: 'end',
                            paddingTop: '135px'
                        }}
                    >
                        <Box component="img" src={logo} alt="Logo Dermatodo" style={{ paddingTop: '10px' }} onClick={reset} />

                        <SearcherComponent barCode={barCode} setBarCode={setBarCode} />

                    </Container>

                    <Container maxWidth={false}
                        sx={{
                            backgroundColor: '#f8bbd0',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '50vh',
                            width: '100vw'
                        }}
                    >

                        <div style={{ color: 'white', fontFamily: 'Montserrat', fontSize: '3rem', paddingTop: '40px' }}>
                            Conoce el precio de tu producto aquí
                        </div>

                        

                        <Box
                            component="img"
                            sx={{
                                width: 150,
                                opacity: '30%',
                                paddingTop: '60px'
                            }}
                            src={barcode}
                        />

                        <FontAwesomeIcon style={{ color: 'white', scale: '6', paddingTop: '8px' }} icon={faChevronDown} beatFade />

                    </Container>

                </Container>

            }













            {(barCode !== '' && !spinner) &&
                <Grid container >

                    <Grid container item xs={4} direction="column"
                        sx={{
                            height: '100vh',
                            width: '30vw',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around'
                        }}
                    >
                        <Box component="img" src={logo} alt="Logo Dermatodo"
                            sx={{ width: '300px' }} onClick={reset}
                        />

                        {carrusel(barCodeList.imagenes)}

                        <SearcherComponent barCode={barCode} setBarCode={setBarCode} style={{  }} />
                        
                        <div style={{ paddingBottom: '50px' }}>
                            <Box component="span" sx={{ p: 1.5, border: '1px solid #00689c' }}>
                                <Button onClick={regresar} style={{ color: '#00689c' }} startIcon={<ArrowBackIosNewIcon />}>
                                    Buscar otro producto
                                </Button>
                            </Box>
                        </div>



                    </Grid>

                    <Grid container item xs={8} direction="column"
                        sx={{
                            backgroundColor: '#fff0f0',
                            width: '70vw',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            height: '100vh',
                            padding: '20px',
                            justifyContent: 'center'
                        }}
                    >

                        <Grid container direction="row" sx={{ justifyContent: 'center' }} style={{ fontFamily: 'Montserrat', padding: '20px' }}>

                            <Box component="span" sx={{ justifyContent: 'center', fontSize: '1.7rem', color: '#1894d2' }}>
                                <div style={{ textAlign: 'center' }}>
                                    {barCodeList.nombre}
                                </div>

                                <div style={{ display: 'block', textAlign: 'center', margin: '6px' }}>
                                    <img src={barCodeList.logo_marca} onError={ handleImagen } width="120" style={{ padding: '10px', backgroundColor: 'white' }} alt=""
                                    />
                                    { altLab && 
                                        <div style={{ fontSize: '1rem', color: 'gray' }}>{barCodeList.laboratorio}</div>
                                    }
                                </div>

                                <div dangerouslySetInnerHTML={{ __html: barCodeList.descripcion_corta }} style={{ textAlign: 'center', fontSize: '1rem', color: 'gray', borderBottomStyle: 'solid', borderBottomWidth: '1px', borderBottomColor: 'lightgray', borderTopStyle: 'solid', borderTopWidth: '1px', borderTopColor: 'lightgray' }}>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#ed8390', textAlign: 'center', fontSize: '1.8rem', fontWeight: 'bold', paddingTop: '10px' }}>

                                    <div>
                                        $ {monedaFormato(barCodeList.precio_final)}
                                    </div>

                                    {(barCodeList.descuento !== 0) &&
                                        <div style={{ display: 'inline-flex', padding: '15px' }}>
                                            <div style={{ color: 'gray', fontSize: '1rem', fontWeight: 'normal', textDecoration: 'line-through', paddingLeft: '0px', paddingRight: '15px' }}>
                                                $ {monedaFormato(barCodeList.pre_precio)}
                                            </div>

                                            <div style={{ display: 'block', backgroundColor: '#ed8390', color: 'white', fontSize: '1.1rem', paddingLeft: '3px', paddingRight: '3px' }}>
                                                {barCodeList.descuento}%
                                            </div>
                                        </div>
                                    }

                                </div>

                                <div style={{ fontSize: '1.2rem', color: 'gray', textAlign: 'center', paddingBottom: '10px', borderBottomStyle: 'solid', borderBottomWidth: '1px', borderBottomColor: 'lightgray' }}>IVA incluído</div>

                                <div style={{ fontSize: '1.2rem', paddingTop: '5px', textAlign: 'center' }}>
                                    Descripción
                                </div>

                                <div dangerouslySetInnerHTML={{ __html: barCodeList.descripcion }} style={{ fontSize: '1rem', color: 'gray', borderBottomStyle: 'solid', borderBottomWidth: '1px', borderBottomColor: 'lightgray', paddingBottom: '10px' }}>
                                </div>

                                <div style={{ fontSize: '1.2rem', padding: '5px', textAlign: 'center' }}>
                                    Detalles del producto
                                </div>

                                <div style={{ fontSize: '1rem', color: 'gray' }}>

                                    { detalles(barCodeList.info) }

                                </div>



                            </Box>

                        </Grid>


                    </Grid>


                </Grid>

            }




        </Container>
    )
};

export default App;